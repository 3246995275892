var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", [_vm._v(" 403 ")]),
      _c("h2", [_vm._v(" Forbidden ")]),
      _vm._v(" "),
      _c("br"),
      _c("p", [
        _vm._v(" Sorry, you must have a valid account to see this page. ")
      ]),
      _c("p", [
        _vm._v(
          " If you believe you have access to this page please contact your system admin. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }