<template>
  <div>
    <h1> 403 </h1>
    <h2> Forbidden </h2> <br>
    <p> Sorry, you must have a valid account to see this page. </p>
    <p> If you believe you have access to this page please contact your system admin. </p>
  </div>
</template>

<script>
export default {
  name: 'Error403',
  data () {
    return {

    }
  }
}

</script>

<style scoped>

div{
  text-align: center;
}
h1{
  font-size: 10rem;
}
P {
  margin-bottom: 0;
}
</style>
